* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  scroll-behavior: smooth;
}
button {
  background-color: #6afcf9;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  outline: none;
  border: 1px solid #6afcf9;
  border-radius: 5px;
}

button:hover {
  background-color: transparent;
  color: #6afcf9;
}

.custom-swal-modal {
  background-color: #453d61 !important;
}

.custom-swal-confirm-button {
  background-color: #6afcf9 !important;
  color: #0e0d0d !important;
  border: 1px solid #6afcf9 !important;
}

.custom-swal-cancel-button {
  background-color: rgb(168, 164, 164) !important;
  color: #131212;
  border: 1px solid rgb(168, 164, 164) !important;
}

.custom-swal-confirm-button:hover {
  background-color: transparent !important;
  color: #6afcf9 !important;
}

.custom-swal-cancel-button:hover {
  background-color: #a00 !important;
}

.map p {
  color: #6afcf9;
  margin: 0;
  font-size: 14px;
}
.footheader:hover a {
  color: rgb(30, 169, 187) !important;
}
