.map {
  min-height: 100vh;
  background-color: #172b4c;
}
.AllMaps {
  min-height: 100vh;
  background-color: #172b4c;
  color: #6afcf9;
}
.map {
  padding: 2;
}
.map h1 {
  color: #6afcf9;
  font-size: 28px;
}
.map h4,
h2 {
  color: #6afcf9;
}
.map h4 {
  font-size: 12px;
  word-break: break-all;
}
.map h2 {
  font-size: 18px;
  margin: 0;
}

.map p {
  color: #6afcf9;
  margin: 0;
  font-size: 14px;
}
.footheader:hover a {
  color: rgb(30, 169, 187) !important;
}

@media screen and (max-width: 500px) {
  .map h1 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 0;
  }
  iframe {
    height: 600px;
  }
}

.map .footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #172b4c;

 }
 @media screen and (max-width: 500px) {
   .map .footer{
   position: relative;
    }
 } 