/* ---home page ----- */
.home {
  min-height: 100vh;
  background-color: #172b4c;
  color: #6afcf9;
  margin: 0;
  position: relative;
}
.navbars {
  position: sticky;
  top: 0;
  z-index: 999;
}

.cardmain {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
/* ----------card list ----------- */

.cards {
  width: 18%;
}

@media screen and (max-width: 768px) {
  .cards {
    width: 48%;
  }
}

@media screen and (max-width: 576px) {
  .cards {
    width: 100%;
  }
}
.profile-card {
  background-color: #453d61;
  border-radius: 20px;
  height: 290px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 1px 1px rgb(209, 207, 207);
  transition: all 0.5s;
  position: relative;
}
.profile-card .status {
  position: absolute;
  top: 10px;
  right: 10px;
}
.profile-card:hover {
  scale: 0.99;
  /* background-color: #7765b6; */
  background-color: #7762be8c;
}

.profile-card img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  border: 2px solid #6afcf9;
}

.name {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 5px;
}

.occupation {
  color: #ffffff;
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.occupation i {
  margin-left: 10px;
}

.cards .map-btn {
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
}
.home h1 {
  font-size: 25px;
}
.app {
  display: none;
}
.website {
  display: inline-block;
}
/* -----media screnn------ */
@media screen and (max-width: 500px) {
  .home h1 {
    font-size: 14px;
  }
  .home h1 img {
    width: 30px;
    height: 30px;
  }
  .navbars {
    padding: 10px;
  }
  .app {
    display: block;
  }
  .website {
    display: none;
  }
}
/* --------add modal------------ */
.addmodal {
  background-color: #453d61;
  padding: 20px;
  color: #6afcf9;
}

.viewmodal,
.MapDetails {
  min-height: 100vh;
  background-color: #172b4c;
  padding: 20px;
  color: #6afcf9;
}

.viewmodal th,
.viewtable th {
  border: 1px solid gray;
  background-color: #796ba1c4;
  color: #6afcf9;
}
.expense table th {
  background-color: rgb(102, 79, 156);
}
.expenseDetail table th {
  border: 1px solid gray;
  background-color: #796ba1c4;
  color: #6afcf9;
}
.expenseDetail table td {
  background-color: #7360a3da;
  border: 1px solid gray;
  color: #6afcf9;
}
.viewmodal td,
.viewtable td {
  background-color: #7360a3da;
  border: 1px solid gray;
  color: #6afcf9;
}
.viewmodal tr:hover td {
  background-color: #5e4179;
}
.viewtable tr:hover td {
  background-color: #5e4179;
}
.addmodal label {
  color: #6afcf9;
}
.addmodal .input {
  border: 1px solid #6afcf9;
  width: 100%;
  height: 35px;
  outline: none;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
  accent-color: #6afcf9;
  color: #6afcf9;
}
.addmodal .select {
  border: 1px solid #6afcf9;
  width: 100%;
  height: 35px;
  outline: none;
  background-color: transparent;
  border-radius: 5px;
  /* padding: 10px; */
  accent-color: #6afcf9;
  color: #6afcf9;
}
.select {
  border: 1px solid #6afcf9;
  width: 100%;
  height: 35px;
  outline: none;
  background-color: #172b4c;
  border-radius: 5px;
  /* padding: 10px; */
  accent-color: #6afcf9;
  color: #6afcf9;
}

.css-13cymwt-control {
  width: 100%;
  background-color: transparent !important;
  border: 1px solid #6afcf9;
}
.select__single-value {
  color: #6afcf9 !important;
}

.select__menu {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.993) !important;
  color: rgb(255, 255, 255) !important;
}
.css-12a83d4-MultiValueRemove {
  color: rgb(0, 0, 0);
}

.select__option {
  padding: 8px 12px;
  color: rgb(0, 0, 0) !important;
}

.addmodal option {
  background-color: #172b4c;
  color: #6afcf9;
}
.addmodal .input1 {
  border: 1px solid #6afcf9;
  width: 100%;
  height: 35px;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  accent-color: #6afcf9;
  color: #6afcf9;
}

.error {
  color: red;
}

.user-details {
  background-color: #583a6b;
  color: #6afcf9;
  width: 280px;
  min-height: 100vh;
  box-shadow: 0px 10px 2px rgba(202, 199, 199, 0.904);
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 1;
  transition: 0.5s;
  padding: 10px;
}
.user-details p {
  color: #6afcf9;
  font-size: 15px;
  text-align: justify;
}
.user-details.active {
  right: 0;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-tabs .nav-link {
  border-radius: 0.25rem 0.25rem 0 0;
  margin-right: 10px;
  background-color: #ffffff;
  color: #000000;
}

.nav-tabs .nav-link.active {
  background-color: #6afcf9;
  color: #000000;
}

.tab-content {
  border: 1px solid #dee2e65b;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 15px;
}
