/* ---------login page ----------- */
.login {
  height: 100vh;
  background-color: #172b4c;
  color: #6afcf9;
  margin: 0;
}
.login .login_wrapper img {
  width: 100%;
  height: 100%;
}

.login .login_wrapper .right label {
  text-transform: capitalize;
  margin-bottom: 3px;
  text-transform: uppercase;
}
.login .login_wrapper .right input {
  width: 300px;
  border: 1px solid #6afcf9;
  height: 35px;
  padding: 4px;
  background-color: transparent;
  accent-color: #6afcf9;
  outline: none;
  border-radius: 5px;
  color: white;
}
.login .login_wrapper .right input::placeholder {
  color: rgba(212, 209, 209, 0.575);
}
.login .right .login_feilds button {
  width: 300px;
  background-color: #6afcf9;
  color: rgb(0, 0, 0);
  padding: 5px 10px;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid #6afcf9;
}
.login .right .login_feilds button:hover {
  background-color: transparent;
  color: #6afcf9;
}

.login .right .error {
  color: red;
  margin: 0;
  height: 22px;
}

.password-toggle {
  border: none !important;
  width: 25px !important;
  background-color: transparent !important;
  position: absolute;
  right: 130px;
}
/* ---------media query----------- */
@media screen and (max-width: 500px) {
  .login {
    height: auto;
  }
  .login .login_wrapper img {
    width: 200px;
    height: 200px;
  }
  .login .login_wrapper .left {
    /* border: 1px solid; */
    text-align: center;
  }
  .password-toggle {
    border: none !important;
    width: 25px !important;
    background-color: transparent !important;
    position: absolute;
    right: 30px;
  }
}
@media screen and (max-width: 800px) {
  .login {
    height: auto;
  }
  .login .login_wrapper img {
    width: 200px;
    height: 200px;
  }
  .login .login_wrapper .left {
    /* border: 1px solid; */
    text-align: center;
  }
}

.login .footer {
  background-color: #172b4c;
}
.loginfooter {
  background-color: #172b4c;
}
